import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (isHorizontal) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  if (isHorizontal) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  } else {
    return {
      id: `vertical-tab-${index}`,
    };
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    height: 300,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const JobList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const experienceItems = {
    "Prodigal AI": {
      jobTitle: "Blockchain Intern @",
      duration: "NOV 2023 - JAN 2024",
      desc: [
        "Made Smart contracts using Solidity on Eth chain using Hardhat and Ganache test chains. Made adequate frontends using react for the same.",
        "Worked on developing a new futuristic cryptographic solution and alternative to e2ee which will emerge as a inevitable solution in the coming of age",
        "explored about open source world of the Blockchain. joined in various Blockchain communities",
      ],
    },
    "unreal ai": {
      jobTitle: "web developer intern @",
      duration: "MAY 2023 - JUL 2023",
      desc: [
        "made responsive react full stack projects using MERN. Attended various boot camps to expand my knowlege in the field. Explored different communities tutored concepts of the web to new recruits there",
        "Collaborated with fellow interns following best practices for the full software development life cycle, including coding standards, code reviews, source control management, testing.",
      ],
    },

    ".exe Club NITH": {
      jobTitle: "Executive Member @",
      duration: "JAN 2021 - APR 2024",
      desc: [
        "Collaborated wih fellow members to build projects. Participated in hackathons. Developed Flutter apps and build real life solutions with it. ",
        "Tutored college students on the basics of Data Structure and Algorithms. Also lectured on web3 future possibilities",
      ],
    },
    "Dhruva Club NITH": {
      jobTitle: "Executive Member @",
      duration: "JAN 2021 - APR 2024",
      desc: [
        "Learned how to do meditation effectively. Worked on self improvement, importance of self discipline. Tutored new students on the same.",
        "Lectured about the self Drug Abuse. Helped many students to streamline there career",
      ],
    },

    "NIT Hamirpur": {
      jobTitle: "Bachelors in CSE @",
      duration: "MAY 2020 - MAY 2024",
      desc: [
        "learned around 48 different subject throughout my education and maintained a CGPA of 8.21.",
        "Participated in hackathons, served as executive member in .exe technical club, participated in college level Chess competitions, attented various bootcamps. collaborated with friends for Hackathons. Just learned galore",
      ],
    },
    "JNV Solan": {
      jobTitle: "Primary and Secondary Education @",
      duration: "APR 2016 - MAY 2020",
      desc: [
        "Learned interpersonal skills. How to live at your own without any parental help. Completed my secondary education with 87.3% in 12th standard",
        "Learned Taekwondo, Chess, Musical Instruments and about life. Participated in NSS and various other olympiads. Winner at National Youth Parliament debate competition and got the prize from Prime Minister at Delhi Parliament",
      ],
    },
    // TDSB: {
    //   jobTitle: "Software Engineer @",
    //   duration: "SEPT 2019 - DEC 2020",
    //   desc: [
    //     "Co-developed homework management software integrable with Google Classroom by utilizing the Python’s Flask micro-framework for the back-end API and Vue.js for the front-end UI, in order to translate business requirements into a functional full-stack application."
    //   ]
    // },
    // "Orange Gate": {
    //   jobTitle: "Software Developer Intern @",
    //   duration: "MAY 2019 - AUG 2019",
    //   desc: [
    //     "Developed a Node.js smart home system through Facebook’s Messenger integrated with Bocco sensors and other smart devices (Nest camera, TPLink smart plugs) to derive conclusions about the current state of the home",
    //     "Identified continuous improvements in data quality, design reports and coding activities, presenting results and findings to internal business stakeholders.",
    //     "Relevant technologies/tools used: DialogFlow, Vision, AutoML, Messenger Bot API, MongoDB.",
    //   ],
    // },
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab label={isHorizontal ? `0${i}.` : key} {...a11yProps(i)} />
        ))}
      </Tabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i}>
          <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
          </span>
          <span className="joblist-job-company">{key}</span>
          <div className="joblist-duration">
            {experienceItems[key]["duration"]}
          </div>
          <ul className="job-description">
            {experienceItems[key]["desc"].map(function (descItem, i) {
              return (
                <FadeInSection delay={`${i + 1}00ms`}>
                  <li key={i}>{descItem}</li>
                </FadeInSection>
              );
            })}
          </ul>
        </TabPanel>
      ))}
    </div>
  );
};

export default JobList;
